export default function ({ store, route, redirect, $axios, $gtm }) {

	if (route.path === '/sms') {
		$gtm.push({ event: 'GAEvent', eventCategory: 'sms', eventAction: 'click_sms', eventLabel: 'reactivation_sms' }); // collect se neobjevuje v Network, ale v dataLayer je
		return redirect('/sutaz');
	}
	else if (route.name === 'registrovat___sk' || route.name === 'jak-soutezit___sk' || route.name === 'denni-vyhry___sk' || route.name === 'hlavni-vyhry-slug___sk' || route.name === 'budoucnost-bez-koure-slug___sk' || route.name === 'prihlasit___cs' || route.name === 'prihlasit___sk') {
		return redirect('/sutaz');
	}

	else if (store.state.userLogin.ageGate === false && route.path === '/prihlasit' && !route.query.retailer) {
		return;
	}
	// else if (app.i18n.locale == 'sk' && store.state.userLogin.ageGate === false && route.path === '/pravidla') {
	// 	return;
	// }
	else if (store.state.userLogin.ageGate === false && route.path === '/reset-hesla/' && !route.query.retailer) {
		return;
	}

	else if (store.state.userLogin.ageGate === false && route.path !== '/' && !route.query.retailer) {
		// console.log(store.state.userLogin.ageGate);
		// console.log(route);
		return redirect('/');
	}

	else if (route.path === '/' && route.query.retailer) {
		$axios.post('/retailer/verify', { retailerSlug: route.query.retailer })
			.then(response => {
				if (response.data.status === 'ok') {
					store.dispatch('userLogin/ageGateConfirmations', true);
					store.dispatch('userLogin/updateField', { fieldName: 'retailerSlug', updatedValue: route.query.retailer });
					store.dispatch('userLogin/updateField', { fieldName: 'hostessName', updatedValue: response.data.payload.name });
					store.dispatch('userLogin/updateField', { fieldName: 'hostessCode', updatedValue: response.data.payload.code });
					return redirect('/registrovat#1');
				}
			})
			.catch((error) => {
				store.dispatch('userLogin/ageGateConfirmations', false);
				store.dispatch('userLogin/updateField', { fieldName: 'retailerSlug', updatedValue: null });
				return redirect('/');
			});
	}

	else if (store.state.userLogin.ageGate === true && route.path === '/') {
		return redirect('/sutaz');
	}
}

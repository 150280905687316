/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	mainPrizes: [],
	mainPrizeMoreInfo: {},
});

export const mutations = {
	MAIN_PRIZES (state, value) {
		state.mainPrizes = value;
	},
	MAIN_PRIZE_MORE_INFO (state, value) {
		state.mainPrizeMoreInfo = value;
	},
};

export const actions = {
	// getMainPrizes ({ commit }) {
	// 	return this.$axios.get('/investments').then(resp => {
	// 		commit('getMainPrizes', resp.data.payload.filter(prizeGroup => prizeGroup.main)[0].prizes);
	// 		return resp;
	// 	});
	// },
	getMainPrizes ({ commit }, payload) {
		commit('MAIN_PRIZES', payload);
	},
	getMainPrizeMoreInfo ({ commit }, payload) {
		commit('MAIN_PRIZE_MORE_INFO', payload);
	},
};

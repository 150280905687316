//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	fetch () {
		this.$axios.$get('/me/coupons')
			.then(response => {
				this.$store.commit('profile/UPDATE_INSERTED_COUPONS', response.payload);
			});
	},

	computed: {
		...mapState({
			insertedCoupons: (state) => state.profile.insertedCoupons,
		}),
	},

	methods: {
		closeModal () {
			this.$store.dispatch('popups/popupCodesProfile', false);
		},
	},
};

import Vue from 'vue';
Vue.directive('gtm-video', {
	bind: (el) => {
		window.dataLayer = window.dataLayer || [];
		const divisor = 25;
		el.videoStatus = {};
		el.gaEvents = {};

		el.videoStatus.greatestMarker = 0;
		el.videoStatus._progressMarkers = {};
		for (var j = 0; j < 100; j++) {
			el.videoStatus.progressPoint = divisor * Math.floor(j / divisor);
			el.videoStatus._progressMarkers[el.videoStatus.progressPoint] = false;
		}
		el.videoStatus.current = 0;

		el.gaEvents.timeupdate = (e) => {
			el.videoStatus.current = Math.round(e.target.currentTime);
			// We just want to send the percent events once
			var pct = Math.floor(100 * el.videoStatus.current / e.target.duration);
			for (var j in el.videoStatus._progressMarkers) {
				if (pct >= j && j > el.videoStatus.greatestMarker) {
					el.videoStatus.greatestMarker = j;
				}
			}
			// current bucket hasn't been already sent to GA?, let's push it to GTM
			if (el.videoStatus.greatestMarker && !el.videoStatus._progressMarkers[el.videoStatus.greatestMarker]) {
				el.videoStatus._progressMarkers[el.videoStatus.greatestMarker] = true;
				window.dataLayer.push({
					event: 'video',
					eventCategory: 'HTML5 Video',
					eventAction: el.videoStatus.greatestMarker + '%',
					// We are using sanitizing the current video src string, and getting just the video name part
					eventLabel: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
				});
			}
		};

		el.gaEvents.play = (e) => {
			window.dataLayer.push({
				event: 'video',
				eventCategory: 'HTML5 Video',
				eventAction: 'Played video',
				eventLabel: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
			});
		};

		el.gaEvents.pause = (e) => {
			window.dataLayer.push({
				event: 'video',
				eventCategory: 'HTML5 Video',
				eventAction: 'Paused video',
				eventLabel: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
				eventValue: el.videoStatus.current,
			});
		};

		el.gaEvents.ended = (e) => {
			window.dataLayer.push({
				event: 'video',
				eventCategory: 'HTML5 Video',
				eventAction: '100%',
				eventLabel: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]),
			});
		};

		Object.keys(el.gaEvents).forEach(eventType => {
			el.addEventListener(eventType, el.gaEvents[eventType], false);
		});
	},
	unbind (el) {
		Object.keys(el.gaEvents).forEach(eventType => {
			el.removeEventListener(eventType, el.gaEvents[eventType], false);
		});
	},
});

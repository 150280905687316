//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data () {
		return {
			showMobileMenu: false,
		};
	},

	computed: {
		gaRegistered () {
			if (this.$auth.loggedIn) {
				return 'R_';
			}
			return '';
		},
	},

	methods: {
		logOut () {
			this.$store.dispatch('userLogin/popupQuestionsIqosScienceLater', false);
			this.$store.dispatch('userLogin/popupMgm', false);
			this.$router.push(this.localePath('/odhlasit'));
		},

		showMessagesModal () {
			this.$router.push(this.localePath({ name: 'profil-zpravy' }));
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		closeModal () {
			this.$store.dispatch('popups/popupCompetitiveProduct', false);
		},

		showPopupCigaretteCode () {
			this.$store.dispatch('popups/popupCompetitiveProduct', false);
			this.$store.dispatch('popups/popupCigaretteCode', true);
		},
	},
};

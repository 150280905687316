export default ({ $auth, $axios }) => {
	window.dataLayer = window.dataLayer || [];

	if (!$auth.loggedIn) {
		window.dataLayer.push({ userId: null, affilation: 'Not Registered' });
	}

	$auth.$storage.watchState('user', (userNew, userOld) => {
		let userId, affilation;
		if ($auth.loggedIn && userNew && userNew.id && userNew.abilityGACommitMainPrizeInvestment) {
			window.dataLayer.push({
				abilityGACommitMainPrizeInvestment: userNew.abilityGACommitMainPrizeInvestment,
			});
			$axios.post('/me/commit-ga-main-prize-invest');
		}
		if ($auth.loggedIn && userNew && userNew.id && userNew.id !== userOld.id) {
			userId = userNew.id;
			affilation = 'Registered';
			window.dataLayer.push({ userId, affilation });
			return;
		}
		if (!$auth.loggedIn) {
			userId = null;
			affilation = 'Not Registered';
			window.dataLayer.push({ userId, affilation });
		}
	});
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		closeModal () {
			this.$store.dispatch('popups/popup2ExtraCodes', false);
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { requiredUnless } from 'vuelidate/lib/validators';

export default {
	data () {
		return {
			popupGratulationProfil: false,
			popupErrorProfil: false,

			brandTypeLabel: null,
			brandTypeId: null,
			brandTypes: [],

			smokelessAlternativeId: null,
			smokelessAlternatives: [],

			mentholPreference: null,

			extraCode: [],

			disabledSendBtn: false,

			questionsAnswered: 0,
		};
	},

	validations () {
		return {
			brandTypeId: {
				requiredIf: requiredUnless(function () {
					return (
						this.smokelessAlternativeId !== null || this.mentholPreference !== null
					);
				}),
			},

			smokelessAlternativeId: {
				requiredIf: requiredUnless(function () {
					return (
						this.brandTypeId !== null || this.mentholPreference !== null
					);
				}),
			},

			mentholPreference: {
				requiredIf: requiredUnless(function () {
					return (
						this.smokelessAlternativeId !== null || this.brandTypeId !== null
					);
				}),
			},
		};
	},

	async fetch () {
		this.$axios.$get('/brands').then(brandResponse => {
			this.brandTypes = brandResponse.payload;
		});

		this.$axios.$get('/smokeless-alternative').then(alternativesResponse => {
			this.smokelessAlternatives = alternativesResponse.payload;
		});
	},

	mounted () {
		this.checkQuestionsAnswered();
	},

	methods: {
		sendQuestions () {
			if (this.$v.$invalid) {
				this.$v.$touch();
			}
			else {
				// Disable button
				this.disabledSendBtn = true;

				this.$axios.post('/surveys/additional-questionnaire', {
					smokelessAlternativeId: this.smokelessAlternativeId,
					mentholPreference: this.mentholPreference !== null ? this.mentholPreference === 'true' : null,
					brandId: this.brandTypeId,
				})
					.then((responseQuestionnaire) => {
						// Enable button after successfully submitting the questionnaire
						this.disabledSendBtn = false;

						// Save extra codes from response to data
						this.extraCode = responseQuestionnaire.data.payload;

						// Open congratulations modal
						this.popupGratulationProfil = true;

						this.$axios.get('/me')
							.then(userMeResponse => {
								this.$auth.setUser(userMeResponse.data.payload);
							})
							.catch((error) => {
								if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
									this.store.dispatch('userLogin/updateFieldErrorMessages', {
										fieldName: 'accessToken',
										updatedValue: error.response.data.context.validation.accessToken,
									});
								}
							});
					});

				// this.$axios.post('/surveys/brand', {
				// 	// brandId: this.brandFilled,
				// 	userSurveyAnswers: [
				// 		{
				// 			surveyAnswerId: this.brandTypeId,
				// 		},
				// 	],
				// })
				// 	.then((responseSurveysProfil) => {
				// 		if (responseSurveysProfil.data.status === 'ok') {
				// 			// Zaktivneni tlacitka po uspesnem odeslani formulare
				// 			this.disabledSendBtn = false;
				//
				// 			this.extraCode = responseSurveysProfil.data.payload;
				//
				// 			this.questionsPopupContent = false;
				// 			this.popupGratulationProfil = true;
				//
				// 			this.$axios.get('/me')
				// 				.then(userMeResponse => {
				// 					this.$auth.setUser(userMeResponse.data.payload);
				// 				})
				// 				.catch((error) => {
				// 					if (error.response.data.code === 401 && error.response.data.context.validation.accessToken) {
				// 						this.store.dispatch('userLogin/updateFieldErrorMessages', {
				// 							fieldName: 'accessToken',
				// 							updatedValue: error.response.data.context.validation.accessToken,
				// 						});
				// 					}
				// 				});
				// 			this.questionsPopupContent = false;
				// 			this.popupGratulationProfil = true;
				// 		}
				// 	})
				// 	.catch(() => {
				// 		// Zaktivneni tlacitka po uspesnem odeslani formulare
				// 		this.disabledSendBtn = false;
				//
				// 		// if (error.response.data.code === 422) {
				// 		this.popupErrorProfil = true;
				// 		this.questionsPopupContent = false;
				// 		this.popupGratulationProfil = false;
				// 		// }
				// 	});
			}
		},

		closeModal () {
			this.$store.dispatch('popups/popupQuestionsProfile', false);

			// this.$axios.$post('/surveys/omission', { surveyId: 2 });
		},

		returnProfilPage () {
			this.popupGratulationProfil = false;
			this.$store.dispatch('popups/popupQuestionsProfile', false);
			this.$router.push(this.localePath({ name: 'denni-vyhry' }));
		},

		updateFieldSelectboxes (fieldName, updatedValue) {
			this.$store.dispatch('userLogin/updateFieldSelecboxes', { fieldName, updatedValue });
		},

		// Get the number of already answered questions
		checkQuestionsAnswered () {
			if (this.$auth.user.brandFilled) {
				this.questionsAnswered = this.questionsAnswered + 1;
			}

			if (this.$auth.user.mentholPreferenceFilled) {
				this.questionsAnswered = this.questionsAnswered + 1;
			}

			if (this.$auth.user.smokelessAlternativeFilled) {
				this.questionsAnswered = this.questionsAnswered + 1;
			}
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		error: {
			type: Object,
			default: () => ({}),
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		align: {
			type: String,
			default: 'top',
		},

		showBtnClose: {
			type: Boolean,
			default: true,
		},

		noScrollDisabled: {
			type: Boolean,
			default: false,
		},
	},

	mounted () {
		this.$store.dispatch('popups/popupNoScroll', true);
	},

	beforeDestroy () {
		if (!this.noScrollDisabled) {
			this.$store.dispatch('popups/popupNoScroll', false);
		}
	},
};

/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	quizPage: [],
});

export const mutations = {
	QUIZ_PAGE (state, value) {
		state.quizPage = value;
	},
};

export const actions = {
	getQuizPage ({ commit }, payload) {
		commit('QUIZ_PAGE', payload);
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		prize: {
			type: Object,
			required: true,
		},
	},
};

/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({

	popupNoScroll: false,
	popupPrizesProfile: false,
	popupCodesProfile: false,
	popupQuestionsProfile: false,
	popupCigaretteCode: false,
	popupCompetitiveProduct: false,
	popupBonusCode: false,
	popupBonusCodeInfo: false,
	popupExtraCodeInfo: false,
	popupMoreInfoMainPrize: false,
	popupFreeChances: false,
	popupHowManyChances: false,
	popupHowGetMainPrize: false,
	popupSuccessMainPrize: false,
	popupSuccessDailyPrize: false,
	popupForgottenPassword: false,
	popupBonusCodeVideo: false,
	popup2ExtraCodes: false,

	popupRulesAndConditions: {
		show: false,
		rulesType: false,
	},
});

export const mutations = {
	POPUP_RESET (currentState) {
		Object.assign(currentState, state());
	},
	POPUP_RULES_AND_CONDITIONS (state, payload) {
		state.popupRulesAndConditions[payload.name] = payload.value;
	},
	POPUP_NO_SCROLL (state, payload) {
		state.popupNoScroll = payload;
	},
	POPUP_QUESTIONS_PROFILE (state, value) {
		state.popupQuestionsProfile = value;
	},
	POPUP_PRIZES_PROFILE (state, value) {
		state.popupPrizesProfile = value;
	},
	POPUP_CODES_PROFILE (state, value) {
		state.popupCodesProfile = value;
	},
	POPUP_CIGARETTE_CODE (state, value) {
		state.popupCigaretteCode = value;
	},
	POPUP_COMPETITIVE_PRODUCT (state, value) {
		state.popupCompetitiveProduct = value;
	},
	POPUP_BONUS_CODE (state, value) {
		state.popupBonusCode = value;
	},
	POPUP_BONUS_CODE_INFO (state, value) {
		state.popupBonusCodeInfo = value;
	},
	popupExtraCodeInfo (state, value) {
		state.popupExtraCodeInfo = value;
	},
	POPUPS_MAIN_PRIZE (state, payload) {
		state[payload.popupName] = payload.value;
	},
	POPUP_SUCCESS_DAILY_PRIZE (state, value) {
		state.popupSuccessDailyPrize = value;
	},
	POPUP_FORGOTTEN_PASSWORD (state, value) {
		state.popupForgottenPassword = value;
	},
	POPUP_BONUS_CODE_VIDEO (state, value) {
		state.popupBonusCodeVideo = value;
	},
	POPUP_2_EXTRA_CODES (state, value) {
		state.popup2ExtraCodes = value;
	},
	POPUP_SHOW (state, payload) {
		state[payload.popupName] = payload.value;
	},
};

export const actions = {
	popupRulesAndConditions ({ commit }, payload) {
		commit('POPUP_RULES_AND_CONDITIONS', payload);
	},
	popupNoScroll ({ commit }, payload) {
		commit('POPUP_NO_SCROLL', payload);
	},
	popupPrizesProfile ({ commit }, payload) {
		commit('POPUP_PRIZES_PROFILE', payload);
	},
	popupQuestionsProfile ({ commit }, payload) {
		commit('POPUP_QUESTIONS_PROFILE', payload);
	},
	popupCodesProfile ({ commit }, payload) {
		commit('POPUP_CODES_PROFILE', payload);
	},
	popupCigaretteCode ({ commit }, payload) {
		commit('POPUP_CIGARETTE_CODE', payload);
	},
	popupCompetitiveProduct ({ commit }, payload) {
		commit('POPUP_COMPETITIVE_PRODUCT', payload);
	},
	popupBonusCode ({ commit }, payload) {
		commit('POPUP_BONUS_CODE', payload);
	},
	popupBonusCodeInfo ({ commit }, payload) {
		commit('POPUP_BONUS_CODE_INFO', payload);
	},
	popupExtraCodeInfo ({ commit }, payload) {
		commit('popupExtraCodeInfo', payload);
	},
	popupsMainPrize ({ commit }, payload) {
		commit('POPUPS_MAIN_PRIZE', payload);
	},
	popupSuccessDailyPrize ({ commit }, payload) {
		commit('POPUP_SUCCESS_DAILY_PRIZE', payload);
	},
	popupForgottenPassword ({ commit }, payload) {
		commit('POPUP_FORGOTTEN_PASSWORD', payload);
	},
	popupBonusCodeVideo ({ commit }, payload) {
		commit('POPUP_BONUS_CODE_VIDEO', payload);
	},
	popup2ExtraCodes ({ commit }, payload) {
		commit('POPUP_2_EXTRA_CODES', payload);
	},
	popupShow ({ commit }, payload) {
		commit('POPUP_SHOW', payload);
	},
};

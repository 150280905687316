export default ({ app, store }) => {
	if (app.i18n.locale === 'cs') {
		if (store.state.userLogin.userRegistration.cigaretteCode === null) {
			store.dispatch('userLogin/updateField', { fieldName: 'cigaretteCode', updatedValue: 'LESTC' });
		}
		store.dispatch('userLogin/updateField', { fieldName: 'phoneCode', updatedValue: '+420' });
	}
	else if (app.i18n.locale === 'sk') {
		if (store.state.userLogin.userRegistration.cigaretteCode === null) {
			store.dispatch('userLogin/updateField', { fieldName: 'cigaretteCode', updatedValue: 'LEDTC' });
		}
		store.dispatch('userLogin/updateField', { fieldName: 'phoneCode', updatedValue: '+421' });
	}
	else {
		return;
	}
};

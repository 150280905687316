//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	name: 'LayoutsNoNav',
	middleware: [ 'ageGate', 'authUser' ],

	head () {
		return {
			script: [
				{
					skip: this.$config.oneTrustData[this.$i18n.locale] === '',
					src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
					id: 'onetrust',
					type: 'text/javascript',
					'data-domain-script': this.$config.oneTrustData[this.$i18n.locale],
					charset: 'UTF-8',
					// body: true,
				},
			],
		};
	},

	computed: {
		...mapState({
			popupCigaretteCode: (state) => state.popups.popupCigaretteCode,
			popupCompetitiveProduct: (state) => state.popups.popupCompetitiveProduct,
			popupRulesAndConditions: (state) => state.popups.popupRulesAndConditions,
		}),
	},
};

/* eslint no-shadow: ["error", { "allow": ["state"] }]*/
import { groupBy } from '~/helpers';

export const state = () => ({
	prizeGroups: [],
	showSuccess: false,
	freeCodes: null,
	mainPrize: [],
});

export const getters = {
	prizeGroups: state => state.prizeGroups,
	prizeGroupsByPrice: state => groupBy(state.prizeGroups, 'price'),
	allPrizes: state => [].concat(...state.prizeGroups.map(group => group.prizes)),
	mainPrize: state => state.mainPrize,
	isSingleCategory: state => Array.isArray(state.prizeGroups) && state.prizeGroups.length === 1,
	freeCodes: state => state.freeCodes,
	prizeGroupByPrizeId: state => prizeId => state.prizeGroups.find(group => group.prizes.some(prize => prize.id === prizeId)),
};

export const mutations = {
	setPrizes (state, prizes) {
		state.prizeGroups = prizes;
	},
	setmainPrize (state, prize) {
		state.mainPrize = prize;
	},
	showSuccess (state, show) {
		state.showSuccess = show;
	},
	setFreeCodes (state, codeCount) {
		state.freeCodes = codeCount;
	},
};

export const actions = {
	getPrizes ({ commit }) {
		return this.$axios.get('/investments').then(resp => {
			commit('setPrizes', resp.data.payload.filter(prizeGroup => !prizeGroup.main));
			commit('setmainPrize', resp.data.payload.filter(prizeGroup => prizeGroup.main));
			return resp;
		});
	},
	getFreeCodes ({ commit }) {
		return this.$axios.get('/me').then(resp => {
			commit('setFreeCodes', resp.data.payload.freeCodes);
			return resp;
		});
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		closeModal () {
			this.$store.dispatch('userLogin/popupMgm', false);
		},
	},
};

import { render, staticRenderFns } from "./PopupPrizesProfile.vue?vue&type=template&id=7168299c&"
import script from "./PopupPrizesProfile.vue?vue&type=script&lang=js&"
export * from "./PopupPrizesProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrizeItem: require('/builds/2oYM8JSf/1/mcp/sk-soutez-2022/frontend/components/PrizeItem.vue').default,ModalWindow: require('/builds/2oYM8JSf/1/mcp/sk-soutez-2022/frontend/components/ModalWindow.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data () {
		return {
			loading: false,
			loaded: false,
		};
	},
	async fetch () {
		this.loading = true;
		await this.$store.dispatch('profile/getMyPrizes');
		this.loading = false;
		this.loaded = true;
	},
	computed: {
		prizes () {
			return this.$store.getters['profile/myPrizes'];
		},
		anyPrizesWon () {
			return this.$store.getters['profile/anyPrizesWon'];
		},
	},
	methods: {
		closeModal () {
			this.$store.dispatch('popups/popupPrizesProfile', false);
		},
	},
};

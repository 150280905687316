export default function () {
	window.OptanonWrapper = () => {
		// GA4 fix (load gtm after onetrust is initialized)
		if (location.host === 'tvojzazitok-test-sk.pmitest.cz') {
			window.$nuxt.$gtm.init('GTM-N5KNNDN');
		}

		if (location.host === 'tvojzazitok.sk') {
			window.$nuxt.$gtm.init('GTM-P45WWPC');
		}

		// force onetrust policy link to open in new window
		const otPolicyText = document.getElementById('onetrust-policy-text');
		if (!otPolicyText) {
			return;
		}
		const otPolicyLink = otPolicyText.querySelector('a');
		if (!otPolicyLink) {
			return;
		}
		otPolicyLink.target = '_blank';
	};
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		closeModal () {
			this.$store.dispatch('popups/popupCigaretteCode', false);
		},

		showPopupCompetitiveProduct () {
			this.$store.dispatch('popups/popupCigaretteCode', false);
			this.$store.dispatch('popups/popupCompetitiveProduct', true);
		},
	},
};

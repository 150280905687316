//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {

	computed: {
		...mapState({
			rules: (state) => state.conditions.rules,
			popupRulesAndConditions: (state) => state.popups.popupRulesAndConditions,
		}),
	},

	async mounted () {
		this.$axios.get('/registration/terms-and-conditions')
			.then((responseTermsAndConditions) => {
				if (responseTermsAndConditions.data.status === 'ok') {
					this.$store.commit('conditions/TERMS_AND_CONDITIONS', responseTermsAndConditions.data.payload);
				}
			});
	},

	methods: {
		closeModal () {
			this.$store.dispatch('popups/popupRulesAndConditions', { name: 'show', value: false });
		},

		rulesType (value) {
			this.$store.dispatch('popups/popupRulesAndConditions', { name: 'rulesType', value: value });
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	methods: {
		showCookiesWindow () {
			/* eslint-disable-next-line */
			window.OneTrust.ToggleInfoDisplay();
		},

		showPopupRulesAndConditions () {
			this.$store.dispatch('popups/popupRulesAndConditions', { name: 'show', value: true });
		},
	},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
	name: 'LayoutsDefault',
	middleware: [ 'ageGate', 'authUser' ],

	async fetch () {
		// Zobrazit popup Questions kdyz se prihlasil potreti
		if (this.$auth.loggedIn && this.$auth.user.iqosFormAutostart && !this.timerPopup.show) {
			this.$store.dispatch('userLogin/popupQuestions', true);
		}

		// Zobrazit popup Questions Iqos Science
		if (this.$auth.loggedIn && this.$auth.user.iqosScienceFormAutostart && !this.timerPopup.show && !this.popupQuestionsIqosScienceLater) {
			this.$store.dispatch('userLogin/popupQuestionsIqosScience', true);
		}

		// Zobrazit popup Questions Iqos Third
		if (this.$auth.loggedIn && this.$auth.user.iqosThirdFormAutostart && !this.timerPopup.show && !this.popupQuestionsIqosThirdLater) {
			this.$store.dispatch('userLogin/popupQuestionsIqosThird', true);
		}

		// Popup 21. 10. - 2 extra codes
		if (this.$auth.loggedIn && this.$auth.user.extraCodesForLoginValid && !this.$auth.user.extraCodesForLoginGained) {
			this.$store.dispatch('popups/popup2ExtraCodes', true);
		}

		// Popup MGM
		if (this.$auth.loggedIn && this.$auth.user.mgmPopupActive) {
			this.$store.dispatch('userLogin/popupMgm', true);
		}
	},

	head () {
		return {
			script: [
				{
					skip: this.$config.oneTrustData[this.$i18n.locale] === '',
					src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
					id: 'onetrust',
					type: 'text/javascript',
					'data-domain-script': this.$config.oneTrustData[this.$i18n.locale],
					charset: 'UTF-8',
					// body: true,
				},
			],
		};
	},

	computed: {
		...mapState({
			popupQuestionsProfile: (state) => state.popups.popupQuestionsProfile,
			popupPrizesProfile: (state) => state.popups.popupPrizesProfile,
			popupCodesProfile: (state) => state.popups.popupCodesProfile,
			popupNoScroll: (state) => state.popups.popupNoScroll,
			userMessages: (state) => state.profile.userMessages,
			timerPopup: (state) => state.userLogin.timerPopup,
			popupQuestionsIqosScience: (state) => state.userLogin.popupQuestionsIqosScience,
			popupQuestionsIqosScienceLater: (state) => state.userLogin.popupQuestionsIqosScienceLater,
			popupQuestionsIqosThird: (state) => state.userLogin.popupQuestionsIqosThird,
			popupQuestionsIqosThirdLater: (state) => state.userLogin.popupQuestionsIqosThirdLater,
			popupCigaretteCode: (state) => state.popups.popupCigaretteCode,
			popupRulesAndConditions: (state) => state.popups.popupRulesAndConditions,
			popup2ExtraCodes: (state) => state.popups.popup2ExtraCodes,
			popupMgm: (state) => state.userLogin.popupMgm,
		}),
	},
};
